import AlecLogo from "@/../public/images/static/alecLogo.png";
import AlecLogoSm from "@/../public/images/static/alecLogoSm.png";
import omniLogo from "@/../public/images/static/OmniLogo.png";
import { getClientByHostname } from "@/lib/chakra/theme";
import { useAppSelector } from "@/lib/hooks";
import Image from "next/image";

type LogoSize = "md" | "sm";

type GetLogoProps = {
  width?: number;
  height?: number;
  size?: LogoSize;
  onLogoClick?: () => void;
};

export const GetLogo = ({
  width = undefined,
  height = undefined,
  size = "md",
  onLogoClick,
}: GetLogoProps) => {
  const { hostname } = useAppSelector((state) => state.app);
  const clientType = getClientByHostname(hostname);

  if (clientType === "OMNI") {
    if (width === undefined) width = 80;
    if (height === undefined) height = 80;

    return (
      <Image
        onClick={() => {
          onLogoClick && onLogoClick();
        }}
        src={omniLogo.src}
        width={width || omniLogo.width}
        height={height || omniLogo.height}
        alt="Omni Logo"
      />
    );
  }
  if (clientType === "ALEC") {
    if (size === "sm") {
      width = 50;
      return (
        <Image
          style={{
            marginLeft: "2px",
          }}
          onClick={() => {
            onLogoClick && onLogoClick();
          }}
          src={AlecLogoSm.src}
          width={width || AlecLogoSm.width}
          height={height || AlecLogoSm.height}
          alt="Omni Logo"
        />
      );
    }
    if ((size = "md")) {
      if (width === undefined) width = 200;
      return (
        <Image
          onClick={() => {
            onLogoClick && onLogoClick();
          }}
          src={AlecLogo.src}
          width={width || AlecLogo.width}
          height={height || AlecLogo.height}
          alt="Omni Logo"
        />
      );
    }
  }

  return <>LOGO</>;
};
